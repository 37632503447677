// Generated by Framer (20caf11)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-YHIgI"

const variantClassNames = {I2OQPmoN1: "framer-v-16y62lz"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.3, ease: [0.5, 0, 0.88, 0.77], type: "tween"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition2 = {damping: 44, delay: 0, mass: 0.9, stiffness: 600, type: "spring"}

const animation = {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1.02, skewX: 0, skewY: 0, transition: transition2}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, image, link, title, width, ...props}) => { return {...props, nqV6nfVZ6: link ?? props.nqV6nfVZ6, TjW3GVRDt: title ?? props.TjW3GVRDt ?? "Buddiez LLC", Z1YaN4zW4: image ?? props.Z1YaN4zW4} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;title?: string;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, nqV6nfVZ6, TjW3GVRDt, Z1YaN4zW4, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "I2OQPmoN1", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={nqV6nfVZ6} smoothScroll={false}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-16y62lz", className, classNames)} framer-h8qb8n`} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"I2OQPmoN1"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-6de06c3d-839d-4cef-85e5-77b99cb2f630, rgb(158, 158, 158))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.h1 style={{"--font-selector": "RlM7Q2xhc2ggRGlzcGxheS1zZW1pYm9sZA==", "--framer-font-family": "\"Clash Display\", \"Clash Display Placeholder\", sans-serif", "--framer-font-size": "20px", "--framer-font-weight": "600", "--framer-line-height": "38px"}}>Buddiez LLC</motion.h1></React.Fragment>} className={"framer-mnd9t1"} data-framer-name={"Title"} fonts={["FS;Clash Display-semibold"]} layoutDependency={layoutDependency} layoutId={"FIrufp2BY"} text={TjW3GVRDt} verticalAlignment={"top"} withExternalLayout/><Image background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + 24 + (((((componentViewport?.height || 410) - 48) - 362) / 2) + 38 + 24))), sizes: `calc(${componentViewport?.width || "100vw"} - 48px)`, ...toResponsiveImage(Z1YaN4zW4)}} className={"framer-1lx0zjo"} data-framer-name={"img"} layoutDependency={layoutDependency} layoutId={"MArwdR6Lk"} style={{borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16}} whileHover={animation}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-YHIgI.framer-h8qb8n, .framer-YHIgI .framer-h8qb8n { display: block; }", ".framer-YHIgI.framer-16y62lz { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 24px; height: min-content; justify-content: center; overflow: visible; padding: 24px; position: relative; text-decoration: none; width: 338px; }", ".framer-YHIgI .framer-mnd9t1 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-YHIgI .framer-1lx0zjo { flex: none; height: 300px; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-YHIgI.framer-16y62lz { gap: 0px; } .framer-YHIgI.framer-16y62lz > * { margin: 0px; margin-bottom: calc(24px / 2); margin-top: calc(24px / 2); } .framer-YHIgI.framer-16y62lz > :first-child { margin-top: 0px; } .framer-YHIgI.framer-16y62lz > :last-child { margin-bottom: 0px; } }", ".framer-YHIgI[data-border=\"true\"]::after, .framer-YHIgI [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 410
 * @framerIntrinsicWidth 338
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"nqV6nfVZ6":"link","TjW3GVRDt":"title","Z1YaN4zW4":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramersBUcKJWA9: React.ComponentType<Props> = withCSS(Component, css, "framer-YHIgI") as typeof Component;
export default FramersBUcKJWA9;

FramersBUcKJWA9.displayName = "bar";

FramersBUcKJWA9.defaultProps = {height: 410, width: 338};

addPropertyControls(FramersBUcKJWA9, {nqV6nfVZ6: {title: "Link", type: ControlType.Link}, TjW3GVRDt: {defaultValue: "Buddiez LLC", title: "Title", type: ControlType.String}, Z1YaN4zW4: {title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramersBUcKJWA9, [{explicitInter: true, fonts: [{family: "Clash Display", source: "fontshare", style: "normal", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/FPDAZ2S6SW4QMSRIIKNNGTPM6VIXYMKO/5HNPQ453FRLIQWV2FNOBUU3FKTDZQVSG/Z3MGHFHX6DCTLQ55LJYRJ5MDCZPMFZU6.woff2", weight: "600"}]}], {supportsExplicitInterCodegen: true})